@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&family=Parisienne&family=Roboto:wght@400;500;700&display=swap');

html{
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #efefef;
  height: 100%
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

#root {
  height: 100%;
}
.App {
  height: 100%;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding:0;
}


:root {
  --color-primary: rgb(94, 154, 140);
  --color-primary-1: rgba(94, 154, 140, 0.88);
  --color-primary-2: rgba(94, 154, 140, 0.21);
  --color-white: white;
  --color-grey-1: rgba(255,255,255, 0.2);
  --color-green: rgb(29, 150, 14);
  --color-red: rgb(198, 34, 34);
  --color-orange: rgb(222, 124, 59);
}
